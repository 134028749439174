import React from "react";
import moment from "moment";
import {
  ProductImage,
  ProductTitle,
  ProductInsignias,
  ProductAttributes,
} from "../../product";
import Price from "../../price/Price";
import { ProductData } from "../../../types/productData";
import { productAttributes } from "../../../api/productAttributes";
import { dateFormat } from "../../../utils/dateFormats";
import { OrderItemColumns, OrderProductData } from "../../../types/order";

interface OrderItemChildRowData {
  deliveryDate: ProductData["deliveryDate"];
  orderItem: OrderProductData;
}

const orderState: Record<string, string> = {
  shipped: "Versendet",
  new: "Offen",
  "payment pending": "Bezahlung offen",
  paid: "Bezahlt",
  exported: "In Abwicklung",
  closed: "Abgeschlossen",
};

/**
 * create single row for CartItemsList
 * @param deliveryDate {ProductData["deliveryDate"]}
 * @param orderItem {ProductData}
 * @constructor
 * @returns {CartItemColumns}
 */
const OrderItemChildRow = ({
  deliveryDate,
  orderItem,
}: OrderItemChildRowData): OrderItemColumns => {
  const {
    name,
    sku,
    metadata,
    deliveryDate: orderDeliveryDate,
    quantity,
    state,
    unitPrice,
    sumPrice,
  } = orderItem;

  /* TODO not yet available in API */
  const attributes: ProductData["attributes"] = {
    [productAttributes.unitQuantity]: 1,
    [productAttributes.package]: "",
    [productAttributes.quality]: "",
    [productAttributes.bioIcon]: "",
    [productAttributes.recommendedRetailPrice]: 0,
  };

  const {
    [productAttributes.unitQuantity]: productUnitQuantity,
    [productAttributes.package]: productUnitMeasurement,
    [productAttributes.quality]: qualitySign,
    [productAttributes.bioIcon]: bioladenLogo,
    [productAttributes.bioFairIcon]: bioFairLogo,
    [productAttributes.recommendedRetailPrice]: recommendedRetailPrice,
  } = attributes;

  const icons = String(qualitySign).split(" ");

  const recommendedRetailPriceNumber: number = parseInt(
    `${recommendedRetailPrice}`.replace(/\D/g, ""),
    10
  );

  return {
    key: sku, // react identifier
    sku,
    product: (
      <div className="itemInfo">
        <ProductImage
          imageSet={metadata?.image}
          className="product-img"
          sku={sku}
        />

        <ProductTitle
          deliveryDate={deliveryDate}
          sku={sku}
          title={name}
          showAsUnavailable={false}
        />

        <ProductAttributes
          attributes={attributes}
          deliveryDate={orderDeliveryDate}
          sku={sku}
        />

        <ProductInsignias
          icons={icons}
          bioladenLogo={bioladenLogo}
          bioladenFairLogo={bioFairLogo}
          orientation="start"
        />
      </div>
    ),
    deliveryDate: (
      <span className="productOrderDateContainer">
        {moment(orderDeliveryDate).format(dateFormat)}
      </span>
    ),
    status: (
      <div className="productStatusContainer">
        {orderState[String(state.name).toLowerCase()]}
      </div>
    ),
    quantity: <div className="productCountContainer">{quantity}</div>,
    unitPrice: (
      <div className="singlePrice">
        {unitPrice && (
          <span className="no-Wrap">
            <Price price={unitPrice} tag="span" className="text-bold" />
            <span className="text-xs">&nbsp;/VPE</span>
          </span>
        )}

        {productUnitQuantity && productUnitMeasurement && (
          <span className="no-Wrap text-s">
            (
            <Price
              price={unitPrice / parseFloat(productUnitQuantity)}
              tag="span"
              className="text-s"
            />{" "}
            / {productUnitMeasurement})
          </span>
        )}
      </div>
    ),
    rrp: (
      <div className="singlePrice">
        {recommendedRetailPriceNumber > 0 && (
          <span className="no-Wrap">
            <Price
              price={recommendedRetailPriceNumber}
              tag="span"
              className="text-bold"
            />
            <span className="text-s">&nbsp; / {productUnitMeasurement}</span>
          </span>
        )}
      </div>
    ),
    total: (
      <div className="sumPrice">
        <Price tag="span" className="text-bold totalPrice" price={sumPrice} />
      </div>
    ),
  };
};

export default OrderItemChildRow;
