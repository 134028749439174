import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";

import { orderOverviewCategoryListEndpoint } from "../endpoints";
import { OrderOverviewCategory } from "../../types/order";

interface GetOrderOverviewCategoryListProps {
  cancelTokenSource: CancelTokenSource;
}

const getOrderOverviewCategoryList = async (
  props: GetOrderOverviewCategoryListProps
) => {
  const { cancelTokenSource } = props;

  return axiosWithAuth()
    .get(`${orderOverviewCategoryListEndpoint}`, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      const items = response?.data?.data?.[0]?.attributes?.items;

      if (response?.status !== 200 || !Array.isArray(items)) {
        Promise.reject(response);
      }

      return items as OrderOverviewCategory[];
    });
};

export default getOrderOverviewCategoryList;
