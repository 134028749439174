import React, { useMemo } from "react";

import Bottleneck from "bottleneck";

import App from "../../App";
import RequestLimiterContext from "../../contexts/RequestLimiterContext";

const RequestLimiterAppWrapper: React.FC = () => {
  const bottleneck = useMemo(
    () => new Bottleneck({ minTime: 500, maxConcurrent: 1 }),
    []
  );

  return (
    <RequestLimiterContext.Provider value={bottleneck}>
      <App />
    </RequestLimiterContext.Provider>
  );
};

export default RequestLimiterAppWrapper;
