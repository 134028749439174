import { useCallback, useEffect, useRef, useState } from "react";

import axios, { CancelTokenSource } from "axios";

import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../api/getCancelTokenSource";
import useCancelAxiosOnUnmount from "./useCancelAxiosOnUnmount";
import requestCatchHandler from "../api/requestCatchHandler";
import getStandingOrders from "../api/standingOrder/getStandingOrders";
import { itemsPerPage } from "../appConfig";
import {
  GetStandingOrdersAttributesResponse,
  StandingOrderData,
  StandingOrdersSorting,
} from "../types/standingOrder";

interface UseGetStStandingOrdersProps {
  deliveryDate: string;
  limit?: number;
  page?: number;
  sort?: StandingOrdersSorting;
}

const useGetStandingOrders = (props: UseGetStStandingOrdersProps) => {
  const {
    deliveryDate,
    limit = itemsPerPage.standingOrders,
    page = 1,
    sort,
  } = props;

  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [standingOrders, setStandingOrders] = useState<StandingOrderData[]>([]);
  const [total, setTotal] = useState<number>(0);

  const fetchStandingOrders = useCallback(async () => {
    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    setIsLoading(true);

    const defaultParams = {
      deliveryDate,
      limit,
      page,
      sort,
      cancelTokenSource: cancelTokenSource.current,
    };

    getStandingOrders({ ...defaultParams })
      .then(
        (
          standingOrdersAttributesResponse: GetStandingOrdersAttributesResponse
        ) => {
          setStandingOrders(
            standingOrdersAttributesResponse?.standingOrders || []
          );
          setTotal(standingOrdersAttributesResponse?.totalResults || 0);
          setIsLoading(false);
        }
      )
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setHasError(true);
          setIsLoading(false);
        }
        requestCatchHandler(error);
      });
  }, [deliveryDate, limit, page, sort]);

  useEffect(() => {
    fetchStandingOrders().catch();
  }, [fetchStandingOrders]);

  return {
    isLoading,
    hasError,
    total,
    standingOrders,
    setTotal,
    setStandingOrders,
  };
};

export default useGetStandingOrders;
