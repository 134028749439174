import { CancelTokenSource } from "axios";
import { allOrdersEndpointWithParameters } from "../endpoints";
import axiosWithAuth from "../axiosWithAuth";
import { DocumentType, OrderType } from "../../types/order";

interface Props {
  page: number;
  limit: number;
  sorting?: string;
  orderType?: OrderType;
  documentType?: DocumentType;
  deliveryDates?: Array<string>;
  invoiceNumber?: string;
  cartNote?: string;
  cancelTokenSource: CancelTokenSource;
}

export interface OrderDataResponse {
  limit: number;
  offset: number;
  orders: Record<string, any>[];
  total: number;
}

/**
 * get all orders based on filter parameter
 * @param page {number}
 * @param limit {number}
 * @param sorting {string}
 * @param orderType {string}
 * @param documentType {string}
 * @param invoiceNumber {string}
 * @param deliveryDates {array}
 * @param cartNote
 * @param cancelTokenSource {CancelTokenSource}
 * @async
 */
const getAllOrders = async ({
  page,
  limit,
  sorting,
  orderType,
  documentType,
  invoiceNumber,
  deliveryDates,
  cartNote,
  cancelTokenSource,
}: Props) => {
  const url = allOrdersEndpointWithParameters({
    limit,
    page,
    sorting,
    orderType,
    documentType,
    invoiceNumber,
    deliveryDates,
    cartNote,
  });
  return axiosWithAuth()
    .get(url, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      const data = response?.data?.data;

      if (!data?.length || !data[0]?.attributes?.orders) {
        return Promise.reject(response);
      }

      return data[0].attributes;
    });
};

export default getAllOrders;
