import React from "react";

import clsx from "clsx";
import { areEqual } from "react-window";

import getAvailabilityState from "../../../../../../utils/getAvailabilityState";
import useGetDeliveryDate from "../../../../../../hooks/useGetDeliveryDate";
import ProductAddToPriceTagListButton from "../../../../../product/ProductAddToPriceTagListButton";
import ProductAddToFavouritesListButton from "../../../../../product/ProductAddToFavouritesListButton";
import ProductInfoBadge from "../../../../../product/ProductInfoBadge";
import useStockForAttribute from "../../../../../../hooks/useStockForAttribute";
import {
  ProductAttributes,
  ProductImage,
  ProductInfoPopUp,
  ProductInsignias,
  ProductPackageInfo,
  ProductTitle,
} from "../../../../../product";
import { productAttributes } from "../../../../../../api/productAttributes";
import { ProductData } from "../../../../../../types/productData";

interface WeekplannerItemProductProps {
  productData: ProductData;
  onClick: (sku: string) => void;
  className?: string;
}

const WeekplannerItemProduct: React.FC<WeekplannerItemProductProps> = (
  props: WeekplannerItemProductProps
) => {
  const { productData, onClick, className } = props;

  const [deliveryDate] = useGetDeliveryDate();

  const {
    imageSets = [],
    attributes = {},
    sku,
    name,
    availabilities = [],
  } = productData;

  const {
    [productAttributes.unitQuantity]: productUnitQuantity,
    [productAttributes.package]: productUnitMeasurement,
    [productAttributes.bioIcon]: bioladenLogo,
    [productAttributes.bioFairIcon]: bioFairLogo,
    [productAttributes.isNewArticle]: isNewArticle,
    [productAttributes.deprecatedSku]: deprecatedSku,
    [productAttributes.qualityText]: qualityText,
    [productAttributes.articleStb]: articleStb,
    [productAttributes.articlePoster]: articlePoster,
  } = attributes;

  const { isUnavailable, explanation, hasNextAvailability } =
    getAvailabilityState(availabilities, []);

  const advertisement = useStockForAttribute(attributes, "advert");
  const deprecatedReason = useStockForAttribute(attributes, "deprecatedReason");

  const img = imageSets?.[0]?.images?.[0]?.externalUrlSmall || "";
  const icons = attributes[productAttributes.quality]
    ? attributes[productAttributes.quality].split(" ")
    : [];

  return (
    <div
      className={clsx(
        "weekplanner-item-cell",
        "weekplanner-item-product",
        className
      )}
    >
      <ProductInfoBadge
        view="weekplanner"
        advertisement={advertisement}
        isNewArticle={isNewArticle}
        deprecatedSku={deprecatedSku}
        deprecatedReason={deprecatedReason}
        isArticlePosterAvailable={articlePoster}
      />

      <ProductImage
        sku={sku}
        imageSet={img}
        alt={name}
        className={clsx(
          { isUnavailable: isUnavailable && !hasNextAvailability },
          "weekplanner-item-product__product-image"
        )}
        imageType="weekplanner"
        onClick={() => {
          if (typeof onClick === "function") {
            onClick(sku);
          }
        }}
      />

      <div className={clsx("weekplanner-item-product__info")}>
        <div>
          <ProductTitle
            deliveryDate={deliveryDate}
            attributes={attributes}
            sku={sku}
            showAsUnavailable={isUnavailable}
            title={name}
            titleClassName="color-primary"
            unavailableHint={explanation}
            onClick={() => {
              if (typeof onClick === "function") {
                onClick(sku);
              }
            }}
          />

          <ProductPackageInfo
            productUnitQuantity={productUnitQuantity}
            productUnitMeasurement={productUnitMeasurement}
            sku={sku}
            showProductUVP
          />

          <ProductInsignias
            icons={icons}
            bioladenLogo={bioladenLogo}
            bioladenFairLogo={bioFairLogo}
            qualityText={qualityText}
            steinofenbaeckerLogo={articleStb}
          />
        </div>

        <ProductAttributes
          attributes={attributes}
          deliveryDate={deliveryDate}
          sku={sku}
        />
      </div>

      <div className={clsx("weekplanner-item-product__actions")}>
        <ProductInsignias
          icons={icons}
          bioladenLogo={bioladenLogo}
          bioladenFairLogo={bioFairLogo}
          qualityText={qualityText}
          steinofenbaeckerLogo={articleStb}
        />
        <div>
          <ProductAddToPriceTagListButton sku={sku} />

          <ProductAddToFavouritesListButton sku={sku} />

          <ProductInfoPopUp
            icons={icons}
            attributes={attributes}
            sku={sku}
            className="infoIcon"
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(WeekplannerItemProduct, areEqual);
