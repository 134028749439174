import React from "react";

import moment from "moment/moment";
import { Link } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { AlignType, FixedType } from "rc-table/lib/interface";

import getProductUVP from "../../../../utils/getProductUVP";
import CheckPriceChangeButton from "../../../molecules/CheckPriceChangeButton";
import Price from "../../../price/Price";
import ProductAddToPriceTagListButton from "../../../product/ProductAddToPriceTagListButton";
import { routePathNames } from "../../../../appConfig";
import { fullDateFormat } from "../../../../utils/dateFormats";
import { OnlyDocumentTypeAsKey } from "../../../../types/order";

const sharedColumns = [
  {
    title: "Lieferdatum",
    width: 80,
    dataIndex: "deliveryDate",
    sorter: true,
    render: (deliveryDate: string) =>
      moment(deliveryDate).format(fullDateFormat),
  },
  {
    title: "Artikelanzahl",
    dataIndex: "orderItems",
    width: 80,
    render: (orderItems: Array<object>) => (
      <>{orderItems?.length || 0} Artikel</>
    ),
    responsive: ["md"],
  },
];

export const columns: OnlyDocumentTypeAsKey = {
  SHOPORDER: [
    {
      title: "Bestellnr.",
      width: 200,
      dataIndex: "orderId",
      render: (
        _: any,
        { orderId, orderNumber }: { orderId: string; orderNumber: string }
      ) => {
        return (
          <h3 style={{ margin: 0 }}>
            {!!orderNumber && (
              <Link to={`${routePathNames.order}${orderId}/${orderNumber}`}>
                {orderNumber}
              </Link>
            )}
            {!orderNumber && orderId}
          </h3>
        );
      },
    },
    {
      title: "Bestelldatum",
      width: 80,
      dataIndex: "createdAt",
      sorter: true,
      render: (deliveryDate: string) =>
        moment(deliveryDate).format(fullDateFormat),
    },
    {
      title: "",
      width: 60,
      dataIndex: "orderId",
      align: "left" as AlignType,
      render: (
        orderId: string,
        { orderItems }: { orderItems: Array<object> }
      ) =>
        orderItems?.length > 0 && (
          <CheckPriceChangeButton orderReference={orderId} />
        ),
    },
    {
      title: "Notiz",
      width: 120,
      dataIndex: "cartNote",
      align: "left" as AlignType,
      // Since we need state rendering of this column is overwritten in OrdersTable component
      render: (cartNote: string) => cartNote || "-",
      responsive: ["md"],
    },
    {
      title: "Bestellweg",
      width: 80,
      dataIndex: "orderChannel",
      render: (orderChannel: string) => orderChannel || "-",
      responsive: ["md"],
    },
    ...sharedColumns,
    {
      title: "",
      width: 1,
      dataIndex: "printShopOrderSmallScreen",
    },
    {
      title: "Gesamtsumme",
      dataIndex: "totals",
      width: 100,
      fixed: "right" as FixedType,
      align: "right" as AlignType,
      render: (totals: any) => (
        <Price
          tag="span"
          className="text-sm text-bold"
          price={totals?.discountTotal || totals?.subtotal || 0}
        />
      ),
    },
    {
      title: "",
      width: 1,
      dataIndex: "printShopOrder",
      fixed: "right" as FixedType,
      align: "right" as AlignType,
    },
  ],
  ORDER: [
    {
      title: "Bestellnr.",
      width: 200,
      dataIndex: "orderId",
      render: (
        _: any,
        { orderId, orderNumber }: { orderId: string; orderNumber: string }
      ) => {
        return (
          <h3 style={{ margin: 0 }}>
            {!!orderNumber && (
              <Link to={`${routePathNames.order}${orderId}/${orderNumber}`}>
                {orderNumber}
              </Link>
            )}
            {!orderNumber && orderId}
          </h3>
        );
      },
    },
    {
      title: "",
      width: 60,
      dataIndex: "orderId",
      align: "left" as AlignType,
      render: (
        orderId: string,
        { orderItems }: { orderItems: Array<object> }
      ) =>
        orderItems?.length > 0 && (
          <CheckPriceChangeButton orderReference={orderId} />
        ),
    },
    {
      title: "Notiz",
      width: 120,
      dataIndex: "cartNote",
      align: "left" as AlignType,
      // Since we need state rendering of this column is overwritten in OrdersTable component
      render: (cartNote: string) => cartNote || "-",
      responsive: ["md"],
    },
    {
      title: "Bestellweg",
      width: 80,
      dataIndex: "orderChannel",
      render: (orderChannel: string) => orderChannel || "-",
      responsive: ["md"],
    },
    ...sharedColumns,
    {
      title: "Gesamtsumme",
      dataIndex: "totals",
      width: 100,
      fixed: "right" as FixedType,
      align: "right" as AlignType,
      render: (totals: any) => (
        <Price
          tag="span"
          className="text-sm text-bold"
          price={totals?.discountTotal || totals?.subtotal || 0}
        />
      ),
    },
  ],
  INVOICE: [
    {
      title: "Rechnungsnr.",
      width: 200,
      dataIndex: "orderNumber", // as invoice number
      // Since we need state rendering of this column is overwritten in OrdersTable component
      render: (invoiceNumber: string) => invoiceNumber || "-",
    },
    ...sharedColumns,
  ],
};

export const subColumns = [
  {
    title: "",
    width: 80,
    dataIndex: "sku",
  },
  {
    title: "Artikelbezeichnung",
    width: 220,
    dataIndex: "name",
    render: (name: string, record: any) => {
      if (!record?.sku) {
        return <>{name}</>;
      }

      return (
        <Link
          to={`${routePathNames.product}${record?.sku}`}
          className="product-link"
        >
          {name}
        </Link>
      );
    },
  },
  {
    title: "Etikett",
    width: 30,
    dataIndex: "sku",
    align: "center" as AlignType,
    render: (sku: string) => {
      return (
        <div className="flex justify-center">
          <ProductAddToPriceTagListButton sku={sku} />
        </div>
      );
    },
  },
  {
    title: "Anzahl",
    width: 50,
    dataIndex: "quantity",
    // if article got cancelled ("genullt") its quantity is neg max int => display as 0
    render: (quantity: number) => (quantity === -2147483648 ? 0 : quantity),
  },
  {
    title: "VPE",
    width: 50,
    dataIndex: "vpeQuantity",
    render: (vpeQuantity: string, record: any) => {
      if (!vpeQuantity && !record?.vpeText) {
        return "-";
      }
      return (
        <span className="text-sm text-bold">
          {vpeQuantity}x {record?.vpeText}
        </span>
      );
    },
  },
  {
    title: "UVP",
    width: 50,
    dataIndex: "rrPrice",
    render: (rrPrice: number, record: any) => {
      const productUVP = getProductUVP(
        rrPrice || 0,
        record?.unitQuantity || 1,
        record?.weighingArticle === "1"
      );

      if (!productUVP) {
        return "-";
      }

      return (
        <Price tag="span" className="text-sm text-bold" price={productUVP} />
      );
    },
  },
  {
    title: "Einzelpreis",
    width: 100,
    dataIndex: "unitPrice",
    render: (unitPrice: number) => (
      <Price tag="span" className="text-sm text-bold" price={unitPrice} />
    ),
  },
  {
    title: "Gesamtsumme",
    width: 160,
    dataIndex: "sumPrice",
    fixed: "right" as FixedType,
    align: "right" as AlignType,
    render: (sumPrice: number) => (
      <Price tag="span" className="text-sm text-bold" price={sumPrice} />
    ),
  },
];
