import { useCallback, useEffect, useRef, useState } from "react";

import axios, { CancelTokenSource } from "axios";

import getAllOrders, { OrderDataResponse } from "../api/order/getAllOrders";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../api/getCancelTokenSource";
import useCancelAxiosOnUnmount from "./useCancelAxiosOnUnmount";
import requestCatchHandler from "../api/requestCatchHandler";
import { DocumentType, OrderType } from "../types/order";

interface UseGetOrderProps {
  page: number;
  limit: number;
  sorting?: string;
  deliveryDateFrom?: string;
  deliveryDateTo?: string;
  orderType?: OrderType;
  documentType?: DocumentType;
  invoiceNumber?: string;
  cartNote?: string;
}

const useGetOrders = (props: UseGetOrderProps) => {
  const {
    page,
    limit,
    sorting,
    deliveryDateFrom,
    deliveryDateTo,
    orderType,
    documentType,
    invoiceNumber,
    cartNote,
  } = props;

  const cancelTokeSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokeSource.current);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [orders, setOrders] = useState<Array<any>>([]);
  const [total, setTotal] = useState<number>(0);

  const getOrders = useCallback(async () => {
    cancelTokeSource.current = cancelAndRenewCancelToken(
      cancelTokeSource.current
    );

    setIsLoading(true);

    const defaultParams = {
      page,
      limit,
      sorting,
      orderType,
      documentType,
      invoiceNumber,
      cartNote,
      cancelTokenSource: cancelTokeSource.current,
    };

    const skipDateParams = !deliveryDateFrom && !deliveryDateTo;
    const params = skipDateParams
      ? defaultParams
      : {
          ...defaultParams,
          deliveryDates: [deliveryDateTo, deliveryDateFrom],
        };

    getAllOrders({ ...params })
      .then((orderData: OrderDataResponse) => {
        setOrders(orderData?.orders || []);
        setTotal(orderData?.total || 0);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setHasError(true);
          setIsLoading(false);
        }
        requestCatchHandler(error);
      });
  }, [
    page,
    limit,
    sorting,
    deliveryDateFrom,
    deliveryDateTo,
    orderType,
    documentType,
    invoiceNumber,
    cartNote,
  ]);

  useEffect(() => {
    getOrders().catch();
  }, [getOrders]);

  return {
    isLoading,
    hasError,
    total,
    orders,
    setTotal,
    setOrders,
  };
};

export default useGetOrders;
