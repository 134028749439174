import React from "react";
import clsx from "clsx";

// png icons - TODO too large, replace with new ones
import BA from "../../static/images/ik_kuerzel/bio_austria-BA.png";
import BS from "../../static/images/ik_kuerzel/bio_suiesse-BS.png";
import DK from "../../static/images/ik_kuerzel/biokreis-DK.png";
import DP from "../../static/images/ik_kuerzel/biopark-DP.png";
import DC from "../../static/images/ik_kuerzel/ecoland-DC.png";
import DW from "../../static/images/ik_kuerzel/ecovin-DW.png";
import DG from "../../static/images/ik_kuerzel/gäa-DG.png";
import IA from "../../static/images/ik_kuerzel/ifoam-IA.png";
import NF from "../../static/images/ik_kuerzel/naturland_fair-NF.png";
import DV from "../../static/images/ik_kuerzel/verbund_oekohoefe-DV.png";

// svg icons
import DB from "../../static/svg/colored/bioland.svg";
import DD from "../../static/svg/colored/demeter.svg";
import DN from "../../static/svg/colored/naturland.svg";
import EG from "../../static/svg/colored/eu_siegel.svg";
import Bioladen from "../../static/svg/colored/bioladen.svg";
import BioladenFair from "../../static/svg/colored/bioladen-fair.svg";
import Steinofenbaecker from "../../static/svg/colored/steinofenbaecker.svg";

interface Props {
  icons: string[];
  className?: string;
  orientation?: "start" | "end";
  bioladenLogo: string;
  qualityText?: string;
  bioladenFairLogo: string;
  steinofenbaeckerLogo?: string;
}
// Has to be replaced my dynamic data
const ik: any = {
  BA,
  BS,
  DK,
  DB,
  DP,
  DD,
  DC,
  DW,
  DG,
  IA,
  NF,
  DN,
  DV,
  EG,
  Bioladen,
  BioladenFair,
  Steinofenbaecker,
};

const conversionNames: string[] = [
  "Bioland i.U.",
  "Demeter i.U.",
  "Naturland i.U.",
  "EG-Bio i.U.",
  "Biokreis i.U.",
];

const insigniasMapping: { [key: string]: string } = {
  "95": "EG",
  "96": "EG",
  "97": "EG",
  "98": "EG",
  "99": "EG",
  "C%": "EG",
  IA: "EG",
};

const ProductInsignias: React.FC<Props> = ({
  icons,
  className,
  orientation = "end",
  bioladenLogo = "0",
  bioladenFairLogo = "0",
  steinofenbaeckerLogo = "0",
  qualityText,
}: Props) => {
  let insignias: string[] = [];

  if (steinofenbaeckerLogo === "1") {
    insignias.push("Steinofenbaecker");
  }

  if (bioladenLogo === "1") {
    if (bioladenFairLogo === "1") {
      insignias.push("BioladenFair");
    } else {
      insignias.push("Bioladen");
    }
  }

  if (icons.length > 0) {
    /*
     * looks dubious?
     * @see {@link https://dev.to/uilicious/javascript-array-push-is-945x-faster-than-array-concat-1oki}
     */
    insignias.push(...icons);

    // Apply mappings
    icons.forEach((icon: string) => {
      if (insigniasMapping?.[icon]) {
        insignias.push(insigniasMapping[icon]);
      }
    });

    // Make sure insignias are valid and unique
    insignias = insignias
      .filter((icon) => !insigniasMapping?.[icon] && !!ik?.[icon])
      .filter((icon, i, a) => a.indexOf(icon) === i);
  }

  const inConversion = conversionNames.includes(qualityText);

  if (insignias.length > 0 || inConversion) {
    return (
      <div
        className={clsx("productInsignias", className, {
          leftAligned: orientation === "start",
        })}
      >
        {!inConversion ? (
          insignias.map((curr: any) => {
            if (ik[curr]) {
              return (
                <img
                  src={ik[curr]}
                  className={clsx(
                    "insignie",
                    curr === "Bioladen" && "bioladenInsignie",
                    curr === "BioladenFair" && "bioladenFairInsignie",
                    curr === "Steinofenbaecker" && "steinofenbaecker"
                  )}
                  key={ik[curr]}
                  alt={`Verbandslogo ${curr}`}
                  role="presentation"
                  title={curr}
                />
              );
            }
            return null;
          })
        ) : (
          <div className="productInsigniasText">{qualityText}</div>
        )}
      </div>
    );
  }
  return null;
};

export default ProductInsignias;
