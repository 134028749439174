import React, { useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import axios, { CancelTokenSource } from "axios";
import { Layout, Row, Col, Result } from "antd";
import moment from "moment";

import ShopCategoryNavigation from "../../navigation/ShopCategoryNavigation";
import LoaderComponent from "../../atoms/LoaderComponent";
import HrDivider from "../../divider/Divider";
import TotalSum from "../../cart/TotalSum";
import getOrderDetail from "../../../api/order/getOrderDetail";
import useGlobalNetworkState from "../../../hooks/useGlobalNetworkState";
import OrderDetailList from "./OrderDetailList";
import getCancelTokenSource from "../../../api/getCancelTokenSource";
import OrderNotes from "./OrderNotes";
import BackButton from "../../backButton/BackButton";
import requestCatchHandler from "../../../api/requestCatchHandler";
import { dateFormat } from "../../../utils/dateFormats";
import {
  pageTitles,
  pageTitleSuffix,
  routePathNames,
} from "../../../appConfig";
import TrackingHelmet from "../../Matomo/TrackingHelmet";
import { OrderData } from "../../../types/order";

const OrderDetail: React.FC = () => {
  const { id: orderId, number: orderNumber } = useParams<{
    id: string;
    number: string;
  }>();

  const [orderData, setOrderData] = useState<OrderData>();
  const [loading, setLoading] = useGlobalNetworkState("component", false);

  /**
   * get orders
   * @param cancelTokenSource {CancelTokenSource}
   */
  const getOrder = useCallback(
    async (cancelTokenSource: CancelTokenSource) => {
      setLoading(true);

      getOrderDetail(orderId, cancelTokenSource)
        .then((result) => {
          let totalItems = 0;
          const { data } = result;

          if (data) {
            data.attributes.items.forEach((curr: any) => {
              totalItems += curr.quantity;
            });
            setOrderData({ ...data, totalItems });
          }

          setLoading(false);
          return null;
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setLoading(false);
          }
          requestCatchHandler(error);
        });
    },
    [orderId, setLoading]
  );

  useEffect(() => {
    const cancelTokenSource = getCancelTokenSource();

    getOrder(cancelTokenSource).catch();

    return () => {
      cancelTokenSource.cancel();
    };
  }, [getOrder]);

  return (
    <>
      <TrackingHelmet title={pageTitles.order} suffix={pageTitleSuffix} />

      <ShopCategoryNavigation />

      {loading && <LoaderComponent />}

      {!loading && orderData && (
        <>
          <Layout className="container-layout container-layout--inner orderDetail">
            <Row justify="space-between" align="middle">
              <Col>
                <BackButton to={routePathNames.orders} />
                <h1>Bestelldetails</h1>
              </Col>
            </Row>
            <Row justify="space-between" align="middle">
              <Col xs={12}>
                <HrDivider size={2} spacingTop="m" spacingBottom="xl" />
              </Col>
            </Row>
            <Row justify="space-between" gutter={32} className="">
              <Col className="customer" xs={12} lg={7}>
                <h2 className="mb-m">NR. {orderNumber}</h2>

                <dl className="customer-data">
                  <dt className="customer-data-key text-medium">
                    Bestelldatum
                  </dt>
                  <dd className="customer-data-value text-medium">
                    {moment(orderData?.attributes?.createdAt).format(
                      dateFormat
                    )}
                  </dd>
                </dl>

                <dl className="customer-data">
                  <dt className="customer-data-key text-medium">Lieferdatum</dt>
                  <dd className="customer-data-value text-medium">
                    {moment(orderData?.attributes?.deliveryDate).format(
                      dateFormat
                    )}
                  </dd>
                </dl>

                {orderData?.attributes?.cartNote && (
                  <>
                    <HrDivider spacingTop="s" spacingBottom="m" />
                    <div className="note-wrapper">
                      <div className="note">
                        <div className="note-title-wrapper">
                          <p className="text-medium text-sm">Notiz</p>
                        </div>
                        <p className="text-medium antd-no-animation note-text">
                          {orderData?.attributes?.cartNote}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </Col>

              <TotalSum
                isCart={false}
                totalSelectedItems={orderData?.totalItems}
                selectedItemsTotal={orderData?.attributes?.totals.grandTotal}
                className="hidden-md-down"
                width={{ lg: 5 }}
              />
            </Row>
          </Layout>

          <Layout className="container-layout container-layout--inner">
            <HrDivider size={2} spacingTop="l" spacingBottom="l" />

            {/* order notes if any */}
            <OrderNotes orderNotes={orderData?.attributes?.orderNotes} />

            <OrderDetailList orderItems={orderData?.attributes?.items} />

            <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
              <TotalSum
                isCart={false}
                totalSelectedItems={orderData?.totalItems}
                selectedItemsTotal={orderData?.attributes?.totals.grandTotal}
                width={{ xs: 12 }}
                className="hidden-lg-up mb-l"
              />
            </Row>
          </Layout>
        </>
      )}

      {!loading && !orderData && (
        <Result title="Es konnten keine Bestellung mit diesen Kritierien gefunden werden!" />
      )}
    </>
  );
};

export default OrderDetail;
