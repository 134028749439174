import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";

import getPageOffset from "../../utils/getPageOffset";
import { itemsPerPage } from "../../appConfig";
import { standingOrderEndpoint } from "../endpoints";
import {
  GetStandingOrdersAttributesResponse,
  StandingOrdersSorting,
} from "../../types/standingOrder";

interface GetStandingOrdersProps {
  deliveryDate: string;
  limit?: number;
  page?: number;
  sort?: StandingOrdersSorting;
  cancelTokenSource: CancelTokenSource;
}

const getStandingOrders = async (props: GetStandingOrdersProps) => {
  const {
    deliveryDate,
    limit = itemsPerPage.standingOrders,
    page = 1,
    sort,
    cancelTokenSource,
  } = props;

  let url = `${standingOrderEndpoint}?deliveryDate=${deliveryDate}&page[limit]=${limit}&page[offset]=${getPageOffset(
    page,
    limit
  )}`;

  if (sort) {
    url = `${url}&sort=${sort}`;
  }

  return axiosWithAuth()
    .get(url, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (!response?.data?.data || response.status !== 200) {
        return Promise.reject(response);
      }

      const { attributes } = response?.data?.data?.[0] || {};

      return attributes as GetStandingOrdersAttributesResponse;
    });
};
export default getStandingOrders;
