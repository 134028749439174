import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useCallback } from "react";
import { locationSearchQueryParameter } from "../appConfig";
import { PageTypes } from "../types/pageTypes";
import { DocumentType } from "../types/order";

export type SortByValue =
  | "default"
  | "sku"
  | "hersteller"
  | "name"
  | "verband"
  | "land"
  | "warengruppe";

interface SearchParameters {
  deliveryDate?: string;
  deliveryDateFrom?: string;
  deliveryDateTo?: string;
  from?: string;
  page?: number;
  searchTerm?: string;
  sku?: string;
  sortBy?: SortByValue;
  sortDirection?: "asc" | "desc";
  to?: string;
  invoiceNumber?: string;
  documentType?: DocumentType;
}

interface UpdateUrlFragments {
  context: PageTypes;
  targetPathname?: string;
  parameters: SearchParameters;
  showFilters?: boolean;
}
/**
 * For updating url search parameters and optionally routing to a new path.
 *
 * The selection of the context value enables passing certain parameters according to the selected value.
 * If you want to omit a paramters value, you can pass "null" for the parameter. Attention: Some parameters cannot be changed manually depending on the context selection
 *
 * @param {string} context
 * @param {string} targetPathname
 * @param {SearchParameters} parameters
 * @param {boolean} showFilters

 *
 * @example
 * const setUpdateUrlFragments = useUpdateUrlFragments();
 * setUpdateUrlFragments({ context: "productList", parameters: { page: 1, deliveryDate: "2022-01-01" }});
 */
const useUpdateUrlFragments = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const {
    searchTerm,
    sku,
    page,
    deliveryDate,
    deliveryDateFrom,
    deliveryDateTo,
    sortBy,
    sortDirection,
    invoiceNumber,
    documentType,
    cartNote,
  } = locationSearchQueryParameter;

  return useCallback(
    ({
      context,
      targetPathname,
      parameters,
      showFilters = true,
    }: UpdateUrlFragments) => {
      const parsedSearch = showFilters ? queryString.parse(search) : null;

      if (!context || !Object.keys(parameters)?.length) {
        return false;
      }

      let parameterSet: Record<string, any>;

      switch (context) {
        // use a single date and a pagination for the lists
        case "productList": {
          parameterSet = {
            ...parsedSearch,
            [searchTerm]: null,
            [sku]: null,
            [page]: parameters?.page || 1,
            [deliveryDate]: parameters?.deliveryDate,
            [deliveryDateFrom]: null,
            [deliveryDateTo]: null,
          };
          break;
        }

        // make sure the weekplanner uses "from - to" dates
        case "weekPlanner": {
          parameterSet = {
            ...parsedSearch,
            [sku]: null,
            [page]: null,
            [deliveryDate]: null,
            [deliveryDateFrom]: parameters?.from,
            [deliveryDateTo]: parameters?.to,
          };
          break;
        }

        case "detailPage": {
          parameterSet = {
            ...parsedSearch,
            [searchTerm]: null,
            [sku]: null,
            [page]: null,
            [deliveryDate]: parameters?.deliveryDate,
            [deliveryDateFrom]: null,
            [deliveryDateTo]: null,
            [sortBy]: null,
            [sortDirection]: null,
          };
          break;
        }

        // sanitize for search on product list
        case "searchProductList": {
          parameterSet = {
            ...parsedSearch,
            [searchTerm]: parameters.searchTerm,
            [sku]: null,
            [page]: parameters?.page || 1,
            [deliveryDate]: parameters?.deliveryDate,
            [deliveryDateFrom]: null,
            [deliveryDateTo]: null,
            [sortBy]: null,
            [sortDirection]: null,
          };
          break;
        }

        // sanitize for search on weekplanner
        case "searchWeekPlanner": {
          parameterSet = {
            ...parsedSearch,
            [searchTerm]: parameters.searchTerm,
            [sku]: null,
            [page]: parameters?.page || 1,
            [deliveryDate]: parameters?.deliveryDate || null,
            [deliveryDateFrom]: parameters?.from,
            [deliveryDateTo]: parameters?.to,
            [sortBy]: null,
            [sortDirection]: null,
          };
          break;
        }

        // remove everything on carts except the delivery date of the cart
        case "cart": {
          parameterSet = {
            [searchTerm]: null,
            [sku]: null,
            [page]: null,
            [deliveryDate]: parameters?.deliveryDate,
            [deliveryDateFrom]: null,
            [deliveryDateTo]: null,
            [sortBy]: null,
            [sortDirection]: null,
          };
          break;
        }

        // update sortBy and sortDirection
        case "sort": {
          parameterSet = {
            ...parsedSearch,
            [sortBy]: parameters.sortBy,
            [sortDirection]: parameters.sortDirection,
          };
          break;
        }

        // update only the pagination, keep the rest
        case "pagination": {
          parameterSet = {
            ...parsedSearch,
            [page]: parameters?.page || 1,
          };
          break;
        }

        case "orders": {
          parameterSet = {
            ...parsedSearch,
            [deliveryDateFrom]: parameters?.deliveryDateFrom || null,
            [deliveryDateTo]: parameters?.deliveryDateTo || null,
            [invoiceNumber]: null,
            [page]: null,
            [documentType]: parameters?.documentType || null,
            [cartNote]: null,
          };
          break;
        }

        // on default omit those parameters, but keep everything else
        default: {
          parameterSet = {
            ...parsedSearch,
            [searchTerm]: null,
            [sku]: null,
            [page]: null,
            [deliveryDate]: null,
            [deliveryDateFrom]: null,
            [deliveryDateTo]: null,
            [sortBy]: null,
            [sortDirection]: null,
          };
          break;
        }
      }

      // if a context is passed, set them up, otherwise ignore it on construction
      const possiblePathname = targetPathname
        ? { pathname: targetPathname }
        : {};

      navigate({
        ...possiblePathname,
        search: queryString.stringify(parameterSet, { skipNull: true }),
      });

      return true;
    },
    [
      deliveryDate,
      deliveryDateFrom,
      deliveryDateTo,
      navigate,
      page,
      search,
      searchTerm,
      sku,
      sortBy,
      sortDirection,
      invoiceNumber,
      documentType,
      cartNote,
    ]
  );
};

export default useUpdateUrlFragments;
