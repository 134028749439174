import { Calendar, message, Modal, Row, Spin } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { messageData, routePathNames } from "../../../appConfig";
import requestCatchHandler from "../../../api/requestCatchHandler";
import {
  apiDateFormat,
  fullDateFormat,
  monthYearFormat,
} from "../../../utils/dateFormats";

import isButtonDisabled, {
  getValidDeliveryDate,
} from "../../../utils/datePicker";
import LinkToCart from "../../../Router/LinkToCart";
import updateCartItemQuantityMulti from "../../../state/actions/updateCartItemQuantityMulti";
import useCancelAxiosOnUnmount from "../../../hooks/useCancelAxiosOnUnmount";
import getCart from "../../../api/cart/getCart";
import { ItemProps } from "../../../types/postCartItemMulti";
import getCancelTokenSource from "../../../api/getCancelTokenSource";
import { ProductData } from "../../../types/productData";
import getCssVariable from "../../../utils/getCssVariable";
import setLocalCart from "../../../state/actions/setLocalCart";
import disabledDatePickerDates from "../../../utils/disabledDatePickerDates";
import { HistoryProductData } from "../../../types/HistoryProductData";
import { OrderProductData } from "../../../types/order";

interface ReorderProps {
  productItems: Array<ProductData | OrderProductData | HistoryProductData>;
  isModalVisible: boolean;
  setIsModalVisible: (isModalVisible: boolean) => void;
  ignoreCurrentDeliveryDate?: boolean;
  modalQuestion?: string;
  reorderSingleSku?: ProductData["sku"];
  updateCurrentCart?: boolean;
  headline?: string;
  onReorderFinish?: () => void;
}

/**
 * modal component with functionality to select cart and reorder given products
 * @constructor
 */
function ReorderModal({
  productItems,
  isModalVisible,
  setIsModalVisible,
  ignoreCurrentDeliveryDate,
  modalQuestion = "",
  reorderSingleSku,
  updateCurrentCart = false,
  headline = "",
  onReorderFinish,
}: ReorderProps) {
  const { cartItems } = useSelector((state: any) => state.currentCart);
  const { id: cartId, deliveryDate } = useSelector(
    (state: any) => state.currentCartMetaData
  );

  const cancelTokenSource = useRef(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [addingToCart, setAddingToCart] = useState(false);

  // use for init and fallback in error case
  const initialCart = useMemo(
    () => ({
      cartId,
      deliveryDate,
      itemsAmount: cartItems?.length,
    }),
    [cartId, deliveryDate, cartItems]
  );
  const [volatileCart, setVolatileCart] = useState(initialCart);

  /*
   * provide a boolean matching the following criteria:
   * the selected date matches the current deliveryDate (cart)
   * and the component is initialized with ignoreCurrentDeliveryDate
   */
  const isDisabledDeliveryDate = useMemo(() => {
    return (
      ignoreCurrentDeliveryDate && deliveryDate === volatileCart.deliveryDate
    );
  }, [volatileCart.deliveryDate, deliveryDate, ignoreCurrentDeliveryDate]);

  const today = moment();
  const momentDeliveryDate = moment(volatileCart.deliveryDate);

  /**
   * cancel handler for modal
   */
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  /**
   * onOk handler for modal
   * add items to selected cart
   */
  const addToCart = async () => {
    setAddingToCart(true);
    const items: ItemProps[] = [];

    productItems
      .filter((productItem) => {
        // filter the items for a single SKU
        if (reorderSingleSku) {
          return productItem.sku === reorderSingleSku;
        }

        return true;
      })
      .map(async (productItem) => {
        const { sku, quantity } = productItem;
        items.push({ sku, quantity: quantity || 1 });
      });

    // pass the current cart deliveryDate to update cart in backend
    const sourceCart = updateCurrentCart
      ? {
          deliveryDate,
        }
      : null;

    updateCartItemQuantityMulti({
      deliveryDate: volatileCart.deliveryDate,
      items,
      cancelTokenSource: cancelTokenSource.current,
      sourceCart,
    })
      .then(() =>
        setLocalCart({
          deliveryDate,
          cancelTokenSource: cancelTokenSource.current,
        })
      )
      .then(() => {
        if (typeof onReorderFinish === "function") {
          onReorderFinish();
        }
      })
      .then(() => {
        /*
         * go to new cart only, if all items where added
         * on a single sku, just stay where you are
         * if it is a single sku, update the redux cart by getting a fresh state
         */
        if (!reorderSingleSku) {
          navigate(
            `${routePathNames.cart}?deliveryDate=${volatileCart.deliveryDate}`,
            {
              state: {
                deliveryDate: volatileCart.deliveryDate,
              },
            }
          );

          return false;
        }
        return true;
      })
      .then(() => {
        setAddingToCart(false);
        setIsModalVisible(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setAddingToCart(false);
          setIsModalVisible(false);

          message.error(
            error?.response?.data?.errors[0]?.detail ||
              messageData.error.unexpected.content
          );
        }

        requestCatchHandler(error);
      });
  };

  /**
   * update cart and order on date change
   * @param date {moment.Moment}
   */
  const getCartInfoByDate = useCallback(
    (date: moment.Moment) => {
      setIsLoading(true);

      const validDeliveryDate = getValidDeliveryDate(date);

      return getCart({
        deliveryDate: validDeliveryDate.format(apiDateFormat),
        cancelTokenSource: cancelTokenSource.current,
      })
        .then((response) => {
          if (!response?.data?.data?.id || !response?.data?.data?.attributes) {
            return Promise.reject(response);
          }

          const { attributes, id } = response.data.data;

          setVolatileCart({
            cartId: id,
            deliveryDate: attributes?.deliveryDate,
            itemsAmount: attributes?.deliveryDateItems?.length,
          });

          setIsLoading(false);

          return response;
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setIsLoading(false);
            message.error(messageData.error.unexpected);

            // default gracefully to initial values
            setVolatileCart(initialCart);
          }

          requestCatchHandler(error);
        });
    },
    [setIsLoading, initialCart]
  );

  /**
   * on change handler
   * @param date {moment.Moment}
   */
  const onDateChange = (date: moment.Moment) => {
    if (date?.isValid() && !date.isSame(momentDeliveryDate)) {
      getCartInfoByDate(date);
    }
  };

  useEffect(() => {
    return () => {
      setAddingToCart(false);
      setIsModalVisible(false);
    };
  }, [setIsModalVisible]);

  return (
    <Modal
      title={headline.length > 0 ? headline : "WÄHLE EINEN LIEFERTAG"}
      visible={isModalVisible}
      onCancelText="Abbrechen"
      onCancel={handleCancel}
      cancelButtonProps={{ disabled: isLoading || addingToCart }}
      okText="Artikel hinzufügen"
      onOk={addToCart}
      okButtonProps={{
        disabled: isLoading || addingToCart || isDisabledDeliveryDate,
      }}
      confirmLoading={addingToCart}
      className="reorderModal"
      destroyOnClose
      /*
       * add up default modal and tooltip z-indices to display this
       * modal on top of tooltips
       * the mask is unfortunately not style-able with selective CSS
       * so it has to be done in JSX
       */
      maskStyle={{
        zIndex:
          Number(getCssVariable("z-index-overall")) +
          Number(getCssVariable("z-index-modal-mask")),
      }}
      wrapProps={{
        style: {
          zIndex:
            Number(getCssVariable("z-index-overall")) +
            Number(getCssVariable("z-index-modal")),
        },
      }}
      closable={!addingToCart}
      maskClosable={!addingToCart}
      keyboard={!addingToCart}
    >
      <div className="calendarWrapper">
        <Calendar
          fullscreen={false}
          value={momentDeliveryDate}
          defaultValue={today}
          onChange={onDateChange}
          disabledDate={disabledDatePickerDates}
          headerRender={() => (
            <Row justify="space-between" className="p-1">
              <div>
                <button
                  type="button"
                  disabled={isButtonDisabled({
                    direction: "prev",
                    date: volatileCart.deliveryDate,
                    unit: "year",
                  })}
                  onClick={() =>
                    getCartInfoByDate(momentDeliveryDate.subtract(1, "year"))
                  }
                >
                  <DoubleLeftOutlined className="icon iconArrow" />
                </button>

                <button
                  type="button"
                  disabled={isButtonDisabled({
                    direction: "prev",
                    date: volatileCart.deliveryDate,
                    unit: "months",
                  })}
                  onClick={() =>
                    getCartInfoByDate(momentDeliveryDate.subtract(1, "months"))
                  }
                >
                  <LeftOutlined className="icon iconArrow" />
                </button>
              </div>

              <p className="text-bold color-primary">
                {momentDeliveryDate.format(monthYearFormat)}
              </p>

              <div>
                <button
                  type="button"
                  disabled={isButtonDisabled({
                    direction: "next",
                    date: volatileCart.deliveryDate,
                    unit: "months",
                  })}
                  onClick={() =>
                    getCartInfoByDate(momentDeliveryDate.add(1, "months"))
                  }
                >
                  <RightOutlined className="icon iconArrow" />
                </button>
                <button
                  type="button"
                  disabled={isButtonDisabled({
                    direction: "next",
                    date: volatileCart.deliveryDate,
                    unit: "year",
                  })}
                  onClick={() =>
                    getCartInfoByDate(momentDeliveryDate.add(1, "year"))
                  }
                >
                  <DoubleRightOutlined className="icon iconArrow" />
                </button>
              </div>
            </Row>
          )}
        />
      </div>

      <Spin
        size="default"
        className="visible m-rl-auto mt-s"
        spinning={isLoading}
      >
        <div className="textWrap">
          {volatileCart.itemsAmount > 0 && (
            <p>
              {isDisabledDeliveryDate ? (
                `Der ${momentDeliveryDate.format(
                  fullDateFormat
                )} ist der aktuelle Warenkorb. Bitte
                  wähle einen alternativen Liefertag aus.`
              ) : (
                <>
                  Im{" "}
                  <LinkToCart deliveryDate={volatileCart.deliveryDate}>
                    Warenkorb für den{" "}
                    {momentDeliveryDate.format(fullDateFormat)}
                  </LinkToCart>{" "}
                  befinde{volatileCart.itemsAmount > 1 ? "n" : "t"} sich bereits{" "}
                  {volatileCart.itemsAmount} Artikel. {modalQuestion}
                </>
              )}
            </p>
          )}
        </div>
      </Spin>
    </Modal>
  );
}

export default ReorderModal;
